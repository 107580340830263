<template>
<!-- eslint-disable max-len -->
  <exterior-page-content-container>
    <p class="text-2xl font-bold text-center text-primary">
      Data Deletion
    </p>
    <div class="md:w-1/2">
      <p>In order to delete your data, please follow these steps</p>
      <div>
        Step 1:
        <a class="underline cursor-pointer" @click="$router.push({name: 'SignIn'})"> Sign In</a>
      </div>
      <p>Step 2: Navigate to the user settings page</p>
      <p>Step 3: Click on the "Delete Account" page</p>
      <br>
      <p>Once you do this, all of your data from facebook as well as KIV will be removed from our servers</p>
    </div>
  </exterior-page-content-container>
</template>

<script>
import ExteriorPageContentContainer from '@/components/shared/containers/ExteriorPageContentContainer.vue';

export default {
  name: 'DataDeletion',
  components: {
    ExteriorPageContentContainer,
  },
  data() {
    return {};
  },
  methods: {
    agree() {
      this.$router.push({ name: 'Register' });
    },
  },
};
</script>
